import * as React from "preact";
import { JSX } from "preact";

export interface IconContext {
  color?: string;
  size?: string;
  class?: string;
  stroke?: string | JSX.SignalLike<string | undefined>; // allow overide stroke color "currentColor"
  fill?: string | JSX.SignalLike<string | undefined>; // allow overide fill color "currentColor"
  strokeWidth?:  number | string | JSX.SignalLike<number | string | undefined>; // allow overide strokeWidth default 0

  style?: JSX.CSSProperties;
  attr?: JSX.SVGAttributes<SVGSVGElement>;
}

export const defaultContext: IconContext = {
  color: undefined,
  size: undefined,
  class: undefined,
  style: undefined,
  attr: undefined,
};

export const defaultIconContext: React.Context<IconContext> = React.createContext && React.createContext(defaultContext);

// denoCacheMetadata={"headers":{"access-control-allow-origin":"*","cache-control":"public, max-age=31536000, s-maxage=31536000, immutable","accept-ranges":"bytes","cf-ray":"906a2057e8ac15eb-SJC","timing-allow-origin":"*","alt-svc":"h3=\":443\"; ma=86400","report-to":"{\"endpoints\":[{\"url\":\"https:\\/\\/a.nel.cloudflare.com\\/report\\/v4?s=lqGhf1ZnirRgTanpup4VTCgN9XLPpR0YeY8villqNjCvyCnWbdFljjvMFdcu28Zd%2FmnVDi6ng51DsvyZYtX7m5%2F2kot%2BSfyNuxpMLC5Mm1qPtPfsRwEnhNwhOaKsUEl4lP4%3D\"}],\"group\":\"cf-nel\",\"max_age\":604800}","content-type":"text/plain; charset=utf-8","x-served-by":"cache-fra-etou8220096-FRA, cache-lga21966-LGA","vary":"Accept-Encoding","nel":"{\"success_fraction\":0.01,\"report_to\":\"cf-nel\",\"max_age\":604800}","strict-transport-security":"max-age=31536000; includeSubDomains; preload","x-content-type-options":"nosniff","x-cache":"HIT, HIT","cf-cache-status":"MISS","cross-origin-resource-policy":"cross-origin","date":"Thu, 23 Jan 2025 19:17:52 GMT","access-control-expose-headers":"*","x-jsd-version-type":"version","server":"cloudflare","etag":"W/\"32a-Bmotoc1mE42JAxp+yFUNnwalsPg\"","x-jsd-version":"1.0.8"},"url":"https://cdn.jsdelivr.net/gh/urielch/react-icons@1.0.8/lib/iconContext.ts","time":1737659872}